import LoadingSpinner from "components/LoadingSpinner";
import { FormEvent, useContext, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { ThemeContext } from "store/ThemeContext";

type ContactFormData = {
  name: string;
  company: string;
  email: string;
  topic: string;
  message: string;
};

const initialFormData: ContactFormData = {
  name: "",
  company: "",
  email: "",
  topic: "",
  message: "",
};

type ActionOnFormData =
  | {
      type:
        | "UPDATE_NAME"
        | "UPDATE_COMPANY"
        | "UPDATE_EMAIL"
        | "UPDATE_TOPIC"
        | "UPDATE_MESSAGE";
      payload: string;
    }
  | { type: "RESET_FORM" };

const reducer = (state: ContactFormData, action: ActionOnFormData) => {
  switch (action.type) {
    case "UPDATE_NAME":
      return { ...state, name: action.payload };
    case "UPDATE_COMPANY":
      return { ...state, company: action.payload };
    case "UPDATE_EMAIL":
      return { ...state, email: action.payload };
    case "UPDATE_TOPIC":
      return { ...state, topic: action.payload };
    case "UPDATE_MESSAGE":
      return { ...state, message: action.payload };
    case "RESET_FORM":
      return initialFormData;
    default:
      throw new Error("unknown type in contact form reducer");
  }
};

const ContactForm = () => {
  const [formData, dispatch] = useReducer(reducer, initialFormData);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { theme } = useContext(ThemeContext);
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmittingForm(true);
    const form = e.currentTarget;
    const formData = new FormData(form);
    formData.append("test", "thing");
    try {
      const response = await fetch("/api/v1/email/submit-contact-form", {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        console.warn("submitting form unsucessful: ");
        //display form submit unsucessful message notification:
        toast("Unsuccesful in sending the message", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: theme || "light",
        });
        return;
      }
    } catch (err) {
      console.warn("Could not complete request", err);
      //display notification to user
      toast("Really easy toast!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme || "light",
      });
    } finally {
      // regardless of successful submit, reset the
      setSubmittingForm(false);
    }
    form.reset();
    dispatch({ type: "RESET_FORM" });
    toast("Succesful in sending the message", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: theme || "light",
    });
  };
  return (
    <form id="contact-form" method="post" onSubmit={handleSubmit}>
      <div className="form-control-group">
        <label htmlFor="name_sender">name*</label>
        <input
          type="text"
          id="name_sender"
          name="name_sender"
          autoComplete="name"
          required
          placeholder="your name..."
          value={formData.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({ type: "UPDATE_NAME", payload: e.target.value });
          }}
          aria-describedby="describes_name_sender"
        />
        <div id="describes_name_sender" className="describedby-form-control">
          please put in a name, this input field is required
        </div>
      </div>
      <div className="form-control-group">
        <label htmlFor="company_sender">company</label>
        <input
          type="text"
          id="company_sender"
          name="company_sender"
          placeholder="your company... (optional)"
          autoComplete="organization"
          value={formData.company}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({ type: "UPDATE_COMPANY", payload: e.target.value });
          }}
        />
      </div>
      <div className="form-control-group">
        <label htmlFor="email">email*</label>
        <input
          type="email"
          id="email"
          name="email_sender"
          placeholder="your email..."
          autoComplete="email"
          required
          value={formData.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({ type: "UPDATE_EMAIL", payload: e.target.value });
          }}
          aria-describedby="describedby_email_sender"
        />
        <div id="describes_name_sender" className="describedby-form-control">
          please put in a valid email, this input field is required
        </div>
      </div>
      <div className="form-control-group">
        <label htmlFor="topic">topic*</label>
        <input
          type="text"
          id="topic"
          name="topic"
          placeholder="topic..."
          required
          value={formData.topic}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({ type: "UPDATE_TOPIC", payload: e.target.value });
          }}
          aria-describedby="describes_topic"
        />
        <div id="describes_topic" className="describedby-form-control">
          please put in a topic, this input field is required
        </div>
      </div>

      <div className="form-control-group">
        <label htmlFor="message">message</label>
        <textarea
          id="message"
          name="message"
          placeholder="type your message in here..."
          value={formData.message}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            dispatch({ type: "UPDATE_MESSAGE", payload: e.target.value });
          }}
        />
      </div>
      <div className="link-button-container">
        <button
          type="submit"
          style={{ display: "flex", justifyContent: "center" }}
          disabled={submittingForm}
        >
          {!submittingForm ? "submit" : <LoadingSpinner />}
          {/* <LoadingSpinner /> */}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
