import React, { useContext, useState } from "react";
import "./ToolbarOptions.css";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FilterOptions,
  ProjectExplorerSettingsContext,
  SortOptions,
  ViewOptions,
} from "../../../../store/ProjectExplorerSettingsContext";
import {
  faFilter,
  faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import SettingsDropdownItem from "./SettingsDropdownItem";
import { Technology } from "store/types/technologies";

const ToolbarOptions = ({
  handleChangeFilter,
  technologies,
}: {
  handleChangeFilter: (filter: FilterOptions) => void;
  technologies: Technology[];
}) => {
  const { settings, dispatchSetting } = useContext(
    ProjectExplorerSettingsContext
  );

  const [dropdownStates, setDropdownStates] = useState<{
    sort: boolean;
    filter: boolean;
    view: boolean;
  }>({ sort: false, filter: false, view: false });

  // const handleClickDropdown = (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   const open = "open";
  //   const classList = e.currentTarget.classList;
  //   if (classList.contains(open)) {
  //     classList.remove(open);
  //     e.currentTarget.parentNode?.setAttribute("aria-expanded", "false");
  //   } else {
  //     classList.add(open);
  //     e.currentTarget.parentNode?.setAttribute("aria-expanded", "true");
  //   }
  // };

  const toggleDropdown = (setting: "sort" | "filter" | "view") => {
    setDropdownStates((prevState) => {
      return {
        ...prevState,
        [setting]: !prevState[setting],
      };
    });
  };

  const handleFocusOutDropdown = (e: React.FocusEvent<HTMLLIElement>) => {
    e.stopPropagation();
    const dropdown = e.currentTarget;
    const dropdown_2 =
      e.relatedTarget?.parentElement?.parentElement?.parentElement;
    if (dropdown === dropdown_2) {
      return;
    }
    e.currentTarget.setAttribute("aria-expanded", "false");
    e.currentTarget
      .querySelector(".dropdown-main-item")
      ?.classList.remove("open");
  };

  return (
    <ul id="toolbarOptionsContainer">
      <li
        className="dropdown"
        aria-expanded={dropdownStates["sort"]}
        onBlur={handleFocusOutDropdown}
      >
        <button
          type="button"
          onClick={() => toggleDropdown("sort")}
          className={`dropdown-main-item ${
            dropdownStates["sort"] ? "open" : "closed"
          }`}
        >
          Sort <FontAwesomeIcon icon={faAngleDown} />
        </button>
        <ul className="dropdown-list">
          {Object.values(SortOptions).map((option: SortOptions) => {
            return (
              <li key={option}>
                {
                  <SettingsDropdownItem
                    onClick={() => {
                      dispatchSetting({ type: "SORT", option: option });
                    }}
                    selected={option === settings.sort}
                  >
                    {option.toLowerCase()}
                  </SettingsDropdownItem>
                }
              </li>
            );
          })}
        </ul>
      </li>
      <li
        className="dropdown"
        aria-expanded={dropdownStates["filter"]}
        onBlur={handleFocusOutDropdown}
      >
        <button
          type="button"
          onClick={() => toggleDropdown("filter")}
          className={`dropdown-main-item ${
            dropdownStates["filter"] ? "open" : "closed"
          }`}
        >
          Filter{" "}
          {settings.filter === "NONE" ? (
            <FontAwesomeIcon icon={faAngleDown} fixedWidth />
          ) : (
            <FontAwesomeIcon icon={faFilter} fixedWidth size="xs" />
          )}
        </button>
        <ul
          className="dropdown-list"
          style={{ maxHeight: "400px", overflowY: "scroll" }}
        >
          {settings.filter !== "NONE" && (
            <li key="remove-filter">
              <SettingsDropdownItem
                onClick={(e) => {
                  handleChangeFilter("NONE");
                  toggleDropdown("filter");
                }}
                selected={false}
              >
                <>
                  remove filter
                  <FontAwesomeIcon icon={faFilterCircleXmark} />
                </>
              </SettingsDropdownItem>
            </li>
          )}

          {/* TEMPORARY OUT OF ORDER BECAUSE OF DATABASE SCHEMA REWRITE, original was skills.map */}
          {technologies.map((technology: Technology) => {
            return (
              <li key={technology.id}>
                <SettingsDropdownItem
                  onClick={(e) => {
                    const filter = [String(technology.id)];
                    handleChangeFilter(filter);
                    // handleClickDropdown(e);
                  }}
                  selected={
                    settings.filter !== "NONE" &&
                    settings.filter.some(
                      (filterTechnologyId) =>
                        filterTechnologyId === String(technology.id)
                    )
                  }
                >
                  {technology.name}
                </SettingsDropdownItem>
              </li>
            );
          })}
        </ul>
      </li>
      {/**VIEW IS ONLY SUPPORTED ON DESKTOP CAUSE OF RESPONSIVENESS ISSUES. */}
      <li
        className="dropdown desktop-only"
        aria-expanded={dropdownStates["view"]}
        onBlur={handleFocusOutDropdown}
      >
        <button
          type="button"
          onClick={() => toggleDropdown("view")}
          className={`dropdown-main-item ${
            dropdownStates["view"] ? "open" : "closed"
          }`}
        >
          View <FontAwesomeIcon icon={faAngleDown} />
        </button>
        <ul className="dropdown-list">
          {Object.values(ViewOptions).map((option) => {
            return (
              <li key={option}>
                <SettingsDropdownItem
                  onClick={() => {
                    dispatchSetting({ type: "VIEW", option: option });
                  }}
                  selected={settings.view === option}
                >
                  {option.toLowerCase()}
                </SettingsDropdownItem>
              </li>
            );
          })}
        </ul>
      </li>
      {/* <li
        className="dropdown"
        aria-expanded={dropdownStates["..."]}
        onBlur={handleFocusOutDropdown}
      >
        <button
          type="button"
          onClick={() => toggleDropdown("...")}
          className={`dropdown-main-item ${
            dropdownStates["..."] ? "open" : "closed"
          }`}
        >
          ... <FontAwesomeIcon icon={faAngleDown} />
        </button>
        <ul className="dropdown-list">{<li>under development</li>}</ul>
      </li> */}
    </ul>
  );
};

export default ToolbarOptions;
