import "./About.css";
import Skills from "../components/Skills";
import useTypewriter from "../hooks/useTypewriter";
import Timeline from "../components/about/Timeline";
import fotocv from "../assets/foto_cv.jpeg";

// import azureIcon from "../assets/icons/azure.svg";
// import devopsIcon from "../assets/icons/devops.svg";
// import excelIcon from "../assets/icons/excel.svg";
// import javascriptIcon from "../assets/icons/javascript.svg";
// import nodeIcon from "../assets/icons/node.svg";
// import pythonIcon from "../assets/icons/python.svg";
// import reactIcon from "../assets/icons/react.svg";
// import scrumIcon from "../assets/icons/scrum.svg";
// import typescriptIcon from "../assets/icons/typescript.svg";

import Technologies from "components/about/Technologies";

const text = "Hello I am Bjorn...";

const About = () => {
  const displayText = useTypewriter(text, 33);
  return (
    <>
      <div id="about-long-introduction" className="hero">
        <article style={{ textAlign: "center" }}>
          <div>
            <img
              src={fotocv}
              width="100px"
              height="100px"
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div
            className={`typewriter ${
              text === displayText ? "finished" : "writting"
            }`}
          >
            <h1 className="text-wrap-pretty">{displayText}</h1>
          </div>
          <p
            className={`center-on-mobile text-wrap-pretty appear-animation ${
              text === displayText ? "visible" : "hidden"
            }`}
          >
            A problem-solving enthusiast. My approach involves finding a
            good-enough solution that fits the problem at hand. Curious about
            how I solve problems? Please take a look!
          </p>
        </article>
      </div>
      <div id="about-skills" className="section card">
        <h2>Skills*</h2>
        <Skills
          styleContainer={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        />
        <div style={{ fontSize: "0.7rem" }} className="hidden-on-mobile">
          <i>
            *The rating indicates the level of knowledge on a scale from 1 to 5,
            where 1 represents a basic understanding and 5 signifies expertise.
          </i>
        </div>
      </div>
      <div id="about-technologies" className="section card">
        <h2>Technologies</h2>
        <Technologies />
      </div>
      <div id="about-timeline" className="section last-before-footer">
        <h2>Timeline</h2>
        <Timeline />
      </div>
      {/* {azureIcon} */}
    </>
  );
};

export default About;
